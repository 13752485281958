import React, { useContext, useEffect, useState, useRef } from 'react';
import {Link, withRouter } from "react-router-dom"
import { SimulationContext } from '../App';
import { db } from "../util/firebase"
import { collection,query, doc, setDoc, getDocs, getDoc, addDoc, Timestamp } from "firebase/firestore"; 

import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { Message } from 'primereact/message';
import { ProgressSpinner } from 'primereact/progressspinner';
//import { BlockUI } from 'primereact/blockui';
import graphsImg from "../layout/GraphsForLandingPage.png"
import ReCAPTCHA from "react-google-recaptcha";
import { Card } from 'primereact/card';
import Chart from 'react-apexcharts'
import draftToHtml from 'draftjs-to-html';
import { convertToRaw, ContentState, convertFromHTML, convertFromRaw, EditorState } from 'draft-js';

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const BLOGS = "blogs";


const AdminPage = (props) => {
    const {allSimulations, setSimulation, setActiveTabIndex } = useContext(SimulationContext);
    const [showModal, setShowModal] = useState(null);
    const [submittingForm, setSubmittingForm] = useState(false);

    const [title, setTitle] = useState("");
    const [date, setDate] = useState("");
    const [errors, setErrors] = useState(null);
    const [editor, setEditor] = useState("");
    const [blogs, setBlogs] = useState([]);
    const [blog, setBlog] = useState("");



    useEffect(() => {
        getBlogs()
        handleLoadBlogPosts()
    }, [])
    useEffect(() => {
        setErrors(null);
    }, [title, date, editor])

    useEffect(() => {
    
        getBlogs()
    }, [blog])


    const handleLoadBlogPosts = async() => {
        const BLOGS = "blogs"
        const q = query(collection(db, BLOGS/* , orderBy("index") */));
        const querySnapshot = await getDocs(q);
        const allBlogs = []

        querySnapshot.forEach((doc) => {
            const blogData = {id: doc.id, ...doc.data()};
            setBlog(blogData);
            allBlogs.push(blogData)
        });
        setBlogs(allBlogs)
        console.log("ALL BLOGS: ", allBlogs)
    }

    const getBlogs = async() => {
        const {blogID} = props.match.params;
        //window.alert(blogID)

        if(blogID){
            const docRef = doc(db, BLOGS, blogID);
            try {
                const docSnap = await getDoc(docRef);
            
                if (docSnap.exists()) {
                    //window.alert(docSnap.data().fullBlogpostHTML)
                    if(docSnap.data().rawEditor)
                       // setEditor(JSON.parse(docSnap.data().editorState));
                        setEditor(EditorState.createWithContent(convertFromRaw(JSON.parse(docSnap.data().rawEditor))));

                    else
                        setEditor(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(docSnap.data().fullBlogpostHTML))));

                setTitle(docSnap.data()?.title);
                setDate(docSnap.data()?.date);
                setBlog()
                } else {
                  console.log("No such document!");
                  return null;
                }
              } catch (error) {
                console.error("Error getting document:", error);
                throw error;
              }

        }
        else{
            setTitle("")
            setBlog()
            setDate("")
            setEditor("")
            const q = query(collection(db, BLOGS));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                const id = doc.id;
                const data = doc.data();
            });
        }
    }

    const submitBlog = async(e) => {
        const {blogID} = props.match.params;
        e.preventDefault();
        const allFieldsFilled = !!title.trim() && !!date.trim() && !!editor;

        if(allFieldsFilled){

            const collectionRef = collection(db, BLOGS);
            //console.log("EDIT: ", convertToRaw(editor.getCurrentContent()))

            const data = {
                fullBlogpostHTML: "" + draftToHtml(convertToRaw(editor.getCurrentContent())),
                rawEditor: JSON.stringify(convertToRaw(editor.getCurrentContent())),
                title,
                date
            }

            if(blogID){
                const docRef = doc(db, BLOGS, blogID);
                await setDoc(docRef, data);
            }
            else{
                await addDoc(collectionRef, data);
            }

            //const docRef = doc(db, "blogs", `${reverseTimestamp}_${newUID}`)
        }
        else{
            const fieldErrors = {}
            if(!title) fieldErrors.title = "Fill in a valid title before submitting."
            if(!date) fieldErrors.date = "Fill in a valid date before submitting"
            if(!editor) fieldErrors.editor = "Fill out the body of the blog post before submitting"
            setErrors(fieldErrors);
        }
    }


    const FooterCopyright = () => <>
        <Divider />
        <h6>Copyright 2023 Moral Dimension LLC, all rights reserved.</h6>
    </>
    const toolbarOptions = {
        options: ['blockType', 'inline', 'list', 'link', 'history', 'colorPicker',],
        inline: {
        options: ['bold', 'italic', 'underline', 'strikethrough'],
        },
        list: {
        options: ['unordered', 'ordered'],
        },
        blockType: {
        options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'P'],
        },
        history: {
        inDropdown: false,
        options: ['undo', 'redo'],
        }
        }

//This simulation studies the survival value of behaviors traditionally regarded as reflecting moral values.
    return (
        <div className="grid" style={{ marginLeft: 12, marginTop: 22, }}>
                        
                        <div className="layout-menuitem-text col-12 md:col-2 lg:col-2 pl-0 pr-2 mt-2"   >
                            <ul className="layout-menu justify-content-center">

                                {[...blogs].map((blogItem) => {
                                    return <li key="home" className="menu-separator " style={{marginBottom: 26}}>
                                    <i style={{ fontSize: "1.25rem" }} className={`layout-menuitem-icon pi pi-fw ${blogItem?.title === blog?.title ? "pi-book" : "pi-bookmark"} ${blogItem?.title === blog?.title ? "layout-menuitem-active" : "layout-menuitem-normal"}`}></i>
                                    <Link // className={"tooltip-item "} data-pr-tooltip={"This will take you to the home/landing page"} data-pr-position="right"
                                        data-pr-at="right+5 top" data-pr-my="left center-2"style={{ cursor: 'pointer', fontSize:"16px" }} to={`/admin-page/${blogItem.id}`}
                                        onClick={() => {setBlog(blogItem)}}
                                        tooltip=''> {blogItem.title}</Link>
                                </li>
                                })}

                            </ul>
                        </div>


                <form onSubmit={submitBlog}>
                <div class="row align-right">
                    <h2 style={{paddingBottom: 20}}><strong>Blog Article Editor</strong></h2>

                    <label htmlFor="title" className="block text-900 text-xl font-medium mb-2">
                        Title
                    </label>
                    <InputText value={title} onChange={e => setTitle(e.target.value)} inputid="title" type="text" placeholder="Title" className="w-full  mb-5" />
                    {errors?.title && 
                        <div className="flex align-items-center justify-content-between mb-5 gap-5">
                            <Message severity="error" text={errors.title} />
                        </div>}

                    <label htmlFor="date" className="block text-900 text-xl font-medium mb-2">
                        Date
                    </label>
                    <InputText value={date} onChange={e => setDate(e.target.value)} inputid="date" type="text" placeholder="Date" className="w-full  mb-5" />
                    {errors?.date && 
                        <div className="flex align-items-center justify-content-between mb-5 gap-5">
                            <Message severity="error" text={errors.date} />
                        </div>}

                    <div style={{  }} >
                        <Editor
                            toolbarOptions={toolbarOptions}
                            wrapperClassName="demo-wrapper"
                            editorClassName="demo-editor"
                            placeholder="Enter blog...."
                            editorStyle={{ border: "1px solid rgba(0, 0, 0, .2)", padding: 8, marginBottom: 10 }}
                            onEditorStateChange={setEditor}
                            defaultEditorState={editor}
                            editorState={editor}
                        />
                    </div>
                </div>
                {errors?.editor && 
                        <div className="flex align-items-center justify-content-between mb-5 gap-5">
                            <Message severity="error" text={errors.editor} />
                        </div>}

                <Button type='submit' label="Submit Blog" className="w-full p-3 text-xl" ></Button>

                </form>
                <FooterCopyright/>

        </div>);
};
export default withRouter(AdminPage);