import React, { useContext, useEffect, useState, useRef } from 'react';
import {Link, withRouter } from "react-router-dom"
import { SimulationContext } from '../App';
import { db } from "../util/firebase"
import { collection, doc, getDocs, setDoc, Timestamp, query, orderBy } from "firebase/firestore"; 

import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { Message } from 'primereact/message';
import { ProgressSpinner } from 'primereact/progressspinner';
//import { BlockUI } from 'primereact/blockui';
import graphsImg from "../layout/GraphsForLandingPage.png"
import ReCAPTCHA from "react-google-recaptcha";
import { Card } from 'primereact/card';
import Chart from 'react-apexcharts'
import { Sidebar } from 'primereact/sidebar';
import { VirtualScroller } from 'primereact/virtualscroller';

const LandingPage = (props) => {
    const recaptcha = useRef();

    const {allSimulations, setSimulation, setActiveTabIndex } = useContext(SimulationContext);
    const [showModal, setShowModal] = useState(null);
    const [submittingForm, setSubmittingForm] = useState(false);

    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [body, setBody] = useState("");
    const [errors, setErrors] = useState(null);
    const [blogs, setBlogs] = useState([]);
    const [blog, setBlog] = useState("");


    useEffect(() => {
        handleLoadBlogPosts()

        setErrors(null);
    }, [email, subject, body])

    const submitContactForm = async(e) => {
        e.preventDefault();
        const allFieldsFilled = !!email.trim() && !!subject.trim() && !!body.trim();
        const captchaValue = recaptcha.current.getValue()
        if(allFieldsFilled && captchaValue){
            setSubmittingForm(true)
            const messagesRef = collection(db, "messages");
            const newUID = doc(messagesRef).id;
            const timestamp = Date.now(Timestamp.now());
            const reverseTimestamp = 4000000000000 - timestamp; //This is **ONLY** done to order the collection in Firestore bottom-to-top
    
            const docRef = doc(db, "messages", `${reverseTimestamp}_${newUID}`)
            const messageData = {email: email, subject: subject, body: body, timestamp: timestamp};
    
            hideModal();
            await setDoc(docRef, messageData);
            closeAndResetContactForm();
        }
        else if(!allFieldsFilled){
            const fieldErrors = {}
            if(!email) fieldErrors.email = "Fill in a valid email before submitting."
            if(!subject) fieldErrors.subject = "Fill out the subject line before submitting."
            if(!body) fieldErrors.body = "Fill out the body before submitting."
            setErrors(fieldErrors);
        }
        else if(!captchaValue){
            setErrors({captcha: "Complete the captcha before submission."});
        }
    }

    const handleLoadBlogPosts = async() => {
        const BLOGS = "blogs"
        const q = query(collection(db, BLOGS/* , orderBy("index") */));
        const querySnapshot = await getDocs(q);
        const allBlogs = []

        querySnapshot.forEach((doc) => {
            const blogData = {id: doc.id, ...doc.data()};
            setBlog(blogData);
            allBlogs.push(blogData)
        });
        setBlogs(allBlogs)
        console.log("ALL BLOGS: ", allBlogs)
    }
    
    const loadBlogpost = () => {

    }


    const hideModal = () => { 
        setShowModal(null);
        setErrors(null);
    }

    const closeAndResetContactForm = () => {
        setEmail("");
        setSubject("");
        setBody("");
        setSubmittingForm(false);
        window.alert("Your message was submitted successfully. Thank you.")
    }

    const LandingPageCard = (cardProps) => {
        const { index=null, header="", mainBody="", icon="", showSpinner=false } = cardProps;
        return (
            <div className="col-12 md:col-12 lg:col-12  pl-0 pr-2 " onClick={() => setShowModal(index)} style={{height:"20%"}}> 
                <div className=" shadow-5 p-3 border-1 border-50 border-round card-background" style={{height:"100%"}}>
                {showSpinner 
                    ? <div style={{display:"flex", alignItems:"center", }}>
                        <ProgressSpinner style={{width: '75px', height: '75px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                    </div>
                    : 
                    <div className="flex justify-content-between mb-3" >
                        <div>
                            <span className="block text-500 font-medium mb-3">{header}</span>
                            <div className="text-900 font-medium text-xl">{mainBody}</div>
                        </div>
                        <div className={`flex align-items-center justify-content-center bg- border-round`} style={{ width: '2.5rem', height: '2.5rem' }}>
                            <i className={`pi ${icon} bg- text-xl`}></i>
                        </div>
                    </div>}
                </div>
            </div>);
    }

    const examplesSeries = {
        datasets: [
            {backgroundColor:"red", data:[1,4, 13, 67, 77, 92]},
            {backgroundColor:"green", data:[76, 20, 32, 34, 91, 93]},
            {backgroundColor:"blue", data:[3,4,7,10, 28, 100]}
    ], 
    labels: [1,2,3,4,5,6,7,8,9,15], graphLabel: "Global Population"
}

    const exampleOptions = {
        chart: {toolbar: {show: false}, },
        tooltip: {enabled: false},
        legend: {show: false},
        title: {
            //text: 'View Clan Data', margin: 1, offsetY: 2, offsetx: -5, style: { fontSize: `18px`, fontWeight: 'bold' },
        },
        xaxis: { //This affects the main tooltips
            tooltip: { enabled: false }, //ONLY for the smaller/simpler tooltip
            type: 'numeric', tickPlacement: 'between', tickAmount: 6, convertedCatToNumeric: true,
            categories: [10, 20, 30, 40, 50, 60]
        },
    }

    const LandingPageResultsSection = () => {
        return(<Link className="layout-menuitem-text col-12 md:col-5 lg:col-5 pl-0 pr-2 mt-2 " to={`/simulations/${allSimulations?.[0]?.title}`} onClick={() => { setActiveTabIndex(0); setSimulation({title: allSimulations[0].title || "Sim title"}) }}>
                <Card  className=" shadow-5 p-3 border-1 border-50 border-round card-background" style={{height:"100%"}}
                 //subTitle="This simulation studies the survival value of behaviors traditionally regarded as reflecting moral values." 
                 //</Link>footer="It also uses biologically plausible differences between males and females to evaluate the possible evolution of systematically different patterns of behavior between the sexes."
                 >
                    <img className='raised-border' src={graphsImg} width={"100%"} ></img>
                    <span className="block text-500 font-medium mb-2 mt-2">Click this section to view the first of our simulations.</span>
                    </Card>
            </Link>)
    }

    const FooterCopyright = () => <>
        <Divider />
        <h6>Copyright 2023 Moral Dimension LLC, all rights reserved.</h6>
    </>

//This simulation studies the survival value of behaviors traditionally regarded as reflecting moral values.
    return (
        <div className="grid"/*  style={{backgroundImage: `url(${back})`, backgroundSize: "cover"}} */>


            <div className="layout-menuitem-text col-12 md:col-4 lg:col-4 pl-0 pr-2 mt-2" 
            style={{display:"flex",flexDirection:"column", justifyContent:"center",backgroundColor:""}}>

    <Card className=" shadow-3 p-0 border-0" style={{background:"linear-gradient(60deg, rgba(0, 0, 0, 0), transparent)"}}>
                <p style={{fontSize:52, fontWeight:550, lineHeight:1.35, fontFamily:"revert", paddingLeft: "2rem", paddingRight: "2rem"}} >A Genetic Algorithm For The Study Of Moral Behavior </p>
                <p style={{fontSize:19, fontWeight:510, fontFamily:"monospace", paddingLeft: "2rem", paddingRight: "2rem"}}></p>

                <Button style={{border:"1px solid white", marginTop:14, backgroundColor:"transparent", color: "white", width: "60%",  marginLeft: "2rem", minHeight:"3.75rem"}}
                text outlined label="View Our Results..." icon="pi pi-chart-line" size="large"
                onClick={() => { setActiveTabIndex(0); window.location.href=`/simulations/${allSimulations?.[0]?.title}` }}>
                </Button>
                </Card>
            </div>

            <LandingPageResultsSection />
        
            <div className="col-12 md:col-3 lg:col-3  pb-0 pr-2  " style={{display:"flex",flexDirection:"column",  justifyContent:"space-between"}}> 
                <LandingPageCard index={0} header="A bit about our work" mainBody="About" icon="pi-users"/>
                <Dialog className='center-modal-header responsive-modals' dismissableMask={true} header="An Overview" visible={showModal === 0} style={{ width: '50vw' }} /* footer={renderFooter('displayBasic')} */ onHide={hideModal}>
                    <h3>What this simulation is, and what it is not</h3>

                    <p>This is a simulation and <strong>does not</strong> claim to describe the complexity of real human genetics or behavior. This study is intended to provoke questions and discussion and should not be construed as providing evidence for claims about human genetics or behavior.</p>

                    <p>This simulation attempts to study the survival value of behaviors traditionally regarded as reflecting moral values.
                    </p>
                    <p>This simulation is not an economic simulation, beyond a very simple trade algorithm.
                    </p>
                    <p>Simplicity is key. If the behavioral simulation is too complex, then it may be simply testing the effect of the quirks of the algorithm. For this reason the behaviors exhibited by our creatures must be as simple as possible, but still reflect the reality of the behavior being performed.</p>
                    <h4>Simulation Parameters</h4>
                    <p>The simulation is controlled by a number of parameters that control a wide variety of aspects, such as ths size of the world, the mutation rate, the energy cost of activities, etc. These parameters and their values are included in the web page for each simulation result.
                    </p>
                    <Divider />

                    <h3>Summary</h3>
                    
                    <h4>Genes and Alleles</h4>
                    <p>Each behavior is controlled by a gene. Each creature has a genetic code that contains an allele of each gene. An allele is a variant of a gene. To clarify what an "allele" is, consider an analogy with a simple physical trait such as eye color. Some people have blue eyes, some have brown. We would say that the blue-eyed person carries the blue allele of the eye color gene. The alleles of the behavior genes give each creature a propensity towards the behavior controlled by that gene.   
                    </p>
                    <h4>Allele Values</h4>
                    <p>An allele holds a value between 0 and 1. The value gives the probability that a trait will "fire", meaning provoke the behavior that it controls.
                    </p>
                    <h4>Dominant and Recessive Genes</h4>
                    <p>Each creature has two "chromosomes", each of which contains an allele for each gene. One chromosome is "dominant" meaning it is active in determining behavior, the other is "recessive" meaning it will be passed on to offspring, but has no effect on behavior.
                    </p>
                    <p>The reason for this is as follows. We want to investigate whether males and females will evolve systematically different behaviors. If an individual were to have only a single chromosome that it were to pass on in the same way to both male and female offspring, there would be no possibility of gender-linked selection.
                    </p>
                    <p>In order to provide gender-linked behavior, each creature has two chromosomes. These are termed "X" and "Y" although the analogy with human X and Y chromosomes is tenuous at best. In females, the X chromosome controls behavior and the Y chromosome is silenced. In males, the Y controls behavior and the X is silenced.
                    </p>
                    <h4>Reproduction</h4>
                    <p>When a male and a female mate and produce offspring, The male's X chromosome is combined with the female's X chromosome to produce the offspring's X chromosome, using a standard genetic algorithm. (Reference: "Genetic Algorithms in Search, Optimization and Machine Learning" by David E. Goldberg.) Similarly the couple's Y chromosomes are combined. In this way the gender-linked behaviors are preserved across generations and evolve independently.</p>
                    <p>Over time, the abundance of each allele in the population will change due to different survival rates for the different alleles.
                    </p>
                    <h4>Gender Differences</h4>
                    <p>Females in the simulation differ from males only in biologically plausible ways. A female has a higher cost for reproduction: she must gestate for a period of time and so cannot reproduce as frequently. Gestation and birth have a resource cost associated with them.
                    </p>
                    <h4>Resources and Trade</h4>
                    <p>The simulation contains two Resources: food and water. A creature requires both to survive and consumes them in each time step of the simulation. Creatures that are located in spots containing resources can harvest them in each time step.
                    </p>
                    <p>Since a given location in the world only has one or the other, creatures must trade resources to survive. A creature may cheat in a trade, and not deliver the resource which it has agreed to deliver.
                    </p>
                    <FooterCopyright />
                </Dialog>

                <LandingPageCard index={1} header="The Science Behind This" mainBody="Algorithm FAQ" icon="pi-id-card"/>
                <Dialog className='center-modal-header responsive-modals' dismissableMask={true} header="The Science Behind This" visible={showModal === 1} style={{ }} /* footer={renderFooter('displayBasic')} */ onHide={hideModal}>
                    <h1>Interactions</h1>
                    <p>Creatures interact through mating, trading, giving charity, and other actions, with their specific behavior influenced by their genes. The influence is probabalistic, meaning that how they behave is random but with a probability given by their particular alleles of each gene.
                    </p>
                    <p>Creatures interact with any of the creatures within an interaction radius that is one of the simulation parameters. Having a large interaction radius means any creature will have a larger choice of other creatures to interact with, for example when selecting a mate.
                    </p>

                    <h2>Trade</h2>
                    <p>One thing that must be emphasized is that this is NOT an economic simulation. Economic simulation is a huge, perhaps impossible, task. The trade algorithm in the Moral Dimension simulator is as simple as possible, so as not to obscure the behavioral aspects controlled by the moral aspects.
                    </p>
                    <p>Nevertheless, there must be some sort of pricing algorithm. The algorithm used is simple, the price is based on the number of trade route hops between the creatures involved in the exchange and the nearest producer.
                    </p>

                    <h2>Clans</h2>
                    <p>Clans are introduced to inject the concept of cultural constraints or government policies into the simulation. What is the effect of shared resources, completely open information, or birth control on the evolution and survival of members of the clan?
                    </p>
                    <p>Creatures only mate within their clan, otherwise tracking the separate evolution of the clan's genome would become meaningless. However trade can occur between clans.
                    </p>
                    <Divider />

                    <h1 className='mt-7'>Historic Events</h1>
                    
                    <p>Events are changes to the environment that can affect the survival, and hence the evolution, of creatures in the simulation. Events are scheduled by simulation parameters.
                    </p>
                    <p>Events affect all clans and occur independently of the behavior or genetics of the clans or individuals.
                    </p>
                    <h2>Sexually-Trasmitted Infection Outbreaks</h2>
                    <p>A disease outbreak will result in a certain percentage of the population being infected. After the initial infection the disease is transmitted with a probability given by the simulation parameters. Other parameters affect the nature of the infection, such as its inclubation period, its mortality rate, and whetner in is transmitted to newborns from the mother. Diseases affect males and females equally. There is no difference in transmission probability or virulence.
                    </p>
                    <h2>SuperNova Explosions</h2>
                    <p>A SuperNova represents a burts of mutation-causing radiation. Creatures' alleles will be randomly changed at a rate and degree specified by simulation parameters.
                    </p>
                    <h2>Droughts</h2>
                    <p>A Drought results in a temporary system-wide reduction in productivity of resources. The severity of the drought, the reduction in productivity, is determined by simulation parameters.
                    </p>
                    <Divider />

                    <h1 className='mt-7'>Behaviors Controlled By Genes</h1>
                    <h6>A brief summary of the overall affect of each gene</h6>

                    <h3>Charitable</h3>
                    <p>If another creature is desperate and asks for help, you are more likely to give it. </p>
                    <h3>Nurturing</h3>
                    <p>The more Nurturing you are, the more generous you are in giving resources to your children. </p>
                    <h3>Gossipy</h3>
                    <p>If you are gossipy, you are more likely to know if another creature has a history of swindling or cheating. However sometimes gossip is malicious and false. </p>
                    <h3>Honest</h3>
                    <p>The less honest you are, the more likely you are to attempt to swindle a trading partner by taking what they offer while not providing what they bargained for. </p>
                    <h3>Greedy</h3>
                    <p>Trade prices are set based on how far a creature is from the source of the commodity. The more greedy you are the more likely you are to add an arbitrary increase to the price you charge in a trade. </p>
                    <h3>Mercenary</h3>
                    <p>The more mercenary you are, the more likely you are to select a spouse based on their wealth. </p>
                    <h3>Selective</h3>
                    <p>The more selective you are, the more likely you are to select a spouse who shares your values, I.E. has similar genetic propensities. </p>
                    <h3>Jealous</h3>
                    <p>The more jealous you are, the more likely you are to divorce a cheating spouse, and the less likely to marry someone with a history of promiscuity. </p>
                    <h3>Libidinous</h3>
                    <p>The more Libidinous you are, the more likely you are to say "Yes" to an opportunity to mate. </p>
                    <h3>Promiscuous</h3>
                    <p>The more promiscuous you are, the more likely you are to have pre-marital or extra-marital sex. </p>
                    <h3>Faithful</h3>
                    <p>The more faithful you are, the less likely you are to mate with a creature other than your spouse. </p>
                    <h3>Family Oriented</h3>
                    <p>The more family oriented you are, the more likely you are to marry.</p>
                    <FooterCopyright />
                </Dialog>

                <LandingPageCard index={3} header="Our plans for coming phases" mainBody="Future" icon="pi-calendar"/>
                <Dialog className='center-modal-header responsive-modals' dismissableMask={true} header="Future Plans" visible={showModal === 3} style={{ width: '50vw' }} /* footer={renderFooter('displayBasic')} */ onHide={hideModal}>
                    <h2>Road Map: Future Enhancements</h2>
                    <p>There are a great many aspects of moral behavior that have yet to be simulated.</p>
                    <p>The challenge with many of these is to produce an algorithm to simulate the behavior which is reasonable, clearly correlates with our conventional interpretation for the behavior, but is simple enough to not be subject to arbitrary anomalies coming from the peculiar details of the algorithm.
                    </p>
                    <Divider />

                    <h2>Forgiveness</h2>
                    <p>The concepts of forgiveness and repentence are a fundamental aspect of our view of morality. This is a tricky aspect to simulate since it involves multiple iterations: Do I forgive someone who has cheated me? Must they show repentence? What if they show repentence but then cheat again?
                    </p>
                    <h2>Sloth</h2>
                    <p>Another traditional moral value we have yet to simulate is laziness, the opposite of productivity. How will other creatures respond to this? Will charity be cut off to that individual? Will this be tied to the Forgiveness characteristic?
                    </p>
                    <h2>Gratitude</h2>
                    <p>This is strongly coupled to the concept of Charity. Charity would have some survival value if Gratitude means that the chariity will be reciprocated. But isn't the nature of Charity that it is given with no hope of reward? If there is no reward, how can selective pressure promote Charity? This may be where the concept of culture is critical. Should there be rewards from the clan for the charitable?
                    </p>
                    <h2>Family Loyalty</h2>
                    <p>Loyalty to one's family is widely considered to be a moral virtue. But does it really just satisfy the sociobiological imperative to improve the survival of your own genes, perhaps at the expense of others in your Clan or in the world?
                    </p>
                    <FooterCopyright />
                </Dialog>

                <LandingPageCard index={4} header="The articles that inspired us" mainBody="Further reading" icon="pi-book"/>
                <Dialog className='center-modal-header responsive-modals' dismissableMask={true} header="Links To Other Works" visible={showModal === 4} style={{ width: '50vw' }} onHide={hideModal}>
                    <h1 >Books</h1>
                    <p className='mb-0'>A brief introduction to the concepts of genetic algorithms can be found in the WikiPedia article:</p>
                    <a  href="https://en.wikipedia.org/wiki/Genetic_algorithm">Genetic Algorithms</a>

                    <p className='mt-4 mb-0'>An excellent book on the mathematics of evolution and its relationship to game theory:</p>
                    <a href="https://a.co/d/iXGCnlb">Evolutionary Dynamics: Exploring the Equations of Life, Martin A. Nowak</a>

                    <p className='mt-4 mb-0'>The classic text on genetic algorithms: </p>
                    <a  href="https://a.co/d/9RX8h8F">Genetic Algorithms in Search, Optimization and Machine Learning, David E. Goldberg</a>

                    <p className='mt-4 mb-0'>Here is an example of the the ability of random variation, followed by selection, to produce results which at first glance seem impossible. This page describes an open-source project (with a link to the source code) that applies a genetic algorithm to reproduce Shakespeare's Hamlet with every character coming from a random number generator:</p>
                    <a href="https://infinitelemurs.wordpress.com/">Infinite Lemurs</a>

                    <FooterCopyright />
                </Dialog>

                <LandingPageCard index={5} header="Get in touch" mainBody="Contact" icon="pi-phone" showSpinner={submittingForm}/>
                <Dialog className='center-modal-header responsive-modals' dismissableMask={true} header="Contact Us" visible={showModal === 5} style={{ width: '50vw' }} onHide={hideModal}>
                    {/* <a href = "mailto: delta@moraldimension.com?subject=Moral Dimension Feedback">delta@moraldimension.com</a> */}
                    <form onSubmit={submitContactForm}>
                        <label htmlFor="email" className="block text-900 text-xl font-medium mb-2">
                            Your Email
                        </label>
                        <InputText value={email} onChange={e => setEmail(e.target.value)} inputid="email" type="text" placeholder="Email" className="w-full  mb-5" />
                        {errors?.email && 
                            <div className="flex align-items-center justify-content-between mb-5 gap-5">
                                <Message severity="error" text={errors.email} />
                            </div>}

                        <label htmlFor="subject" className="block text-900 font-medium text-xl mb-2">
                            Subject
                        </label>
                        <InputText value={subject} onChange={e => setSubject(e.target.value)} inputid="subject" type="text" placeholder="Subject" className="w-full  mb-5" />
                        {errors?.subject && 
                            <div className="flex align-items-center justify-content-between mb-5 gap-5">
                                <Message severity="error" text={errors.subject} />
                            </div>}

                        <label htmlFor="body" className="block text-900 font-medium text-xl mb-2">
                            Body
                        </label>
                        <InputTextarea rows={6} value={body} onChange={e => setBody(e.target.value)} inputid="body" type="text" placeholder="Body" className="w-full  mb-5"/>
                        {errors?.body && 
                            <div className="flex align-items-center justify-content-between mb-5 gap-5">
                                <Message severity="error" text={errors.body} />
                            </div>}

                        <h5>We appreciate feedback and discussion regarding the simulation and related topics.</h5>
                        {errors?.captcha && 
                            <div className="flex align-items-center justify-content-between mb-5 gap-5">
                                <Message severity="error" text={errors.captcha} />
                            </div>}
                        <ReCAPTCHA ref={recaptcha} sitekey={process.env.REACT_APP_SITE_KEY} />
                        <Button type='submit' label="Send message" className="w-full p-3 text-xl" ></Button>
                    </form>
                    <FooterCopyright />
                </Dialog>

                <LandingPageCard index={6} header="View the discussion" mainBody="Project Log" icon="pi-calendar"/>
                <Dialog className='center-modal-header' dismissableMask={true} header="Project Log" visible={showModal === 6} style={{ width: '60vw' }}  onHide={hideModal}>
                
                    <div className="grid">
                        <div className="layout-menuitem-text col-12 md:col-2 lg:col-2 pl-0 pr-2 mt-2"  style={{display:"flex",flexDirection:"column",}}>
                            <ul className="layout-menu justify-content-center">

                                {[...blogs].map((blogItem) => {
                                    return <li key="home" className="menu-separator " style={{marginBottom: 26}}>
                                    <i style={{ fontSize: "1.25rem" }} className={`layout-menuitem-icon pi pi-fw ${blogItem?.title === blog?.title ? "pi-book" : "pi-bookmark"} ${blogItem?.title === blog?.title ? "layout-menuitem-active" : "layout-menuitem-normal"}`}></i>
                                    <Link // className={"tooltip-item "} data-pr-tooltip={"This will take you to the home/landing page"} data-pr-position="right"
                                        data-pr-at="right+5 top" data-pr-my="left center-2"style={{ cursor: 'pointer', fontSize:"16px" }} to="/home"
                                        onClick={() => {setBlog(blogItem)}}
                                        tooltip=''> {blogItem.title}</Link>
                                </li>
                                })}

                            </ul>
                        </div>
                        <div className="layout-menuitem-text col-12 md:col-10 lg:col-10 pl-0 pr-2 mt-2"  style={{display:"flex",flexDirection:"column",}}>
                            <div dangerouslySetInnerHTML={{ __html: blog.fullBlogpostHTML }} />
                        </div>


                    </div>
                    <FooterCopyright />
                </Dialog>

            </div>

        </div>);
};
export default withRouter(LandingPage);